/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "divide-color",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#divide-color",
    "aria-label": "divide color permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Divide Color"), "\n", React.createElement(_components.p, null, "Utilities for controlling the border color between elements."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "divideColor={color}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "border-color: {color};"))))), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, "Control the border color between elements using the ", React.createElement(_components.code, null, "divideColor={color}"), " utility."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=fuchsia"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"grid\"\n      gridTemplateColumns={3}\n      divideX\n      divideColor=\"fuchsia-500\"\n    >\n      <x.div\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        fontSize=\"2xl\"\n        color=\"fuchsia-600\"\n        px={6}\n      >\n        1\n      </x.div>\n      <x.div\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        fontSize=\"2xl\"\n        color=\"fuchsia-600\"\n        px={6}\n      >\n        2\n      </x.div>\n      <x.div\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        fontSize=\"2xl\"\n        color=\"fuchsia-600\"\n        px={6}\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div divideX divideColor=\"fuchsia-500\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h3, {
    id: "changing-opacity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#changing-opacity",
    "aria-label": "changing opacity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Changing opacity"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.a, {
    href: "/docs/colors/"
  }, "color alpha variants"), " to choose another opacity."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div\n      display=\"grid\"\n      gridTemplateColumns={3}\n      divideX\n      divideColor=\"light-blue-500-a50\"\n    >\n      <x.div\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        fontSize=\"2xl\"\n        color=\"light-blue-600\"\n        px={6}\n      >\n        1\n      </x.div>\n      <x.div\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        fontSize=\"2xl\"\n        color=\"light-blue-600\"\n        px={6}\n      >\n        2\n      </x.div>\n      <x.div\n        textAlign=\"center\"\n        fontWeight=\"extrabold\"\n        fontSize=\"2xl\"\n        color=\"light-blue-600\"\n        px={6}\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div divideX divideColor=\"light-blue-500-a50\">\n    <div>1</div>\n    <div>2</div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the border color between elements at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "divideColor={{ md: \"red-500\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "divideColor=\"red-500\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div divideColor={{ md: 'red-500' }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
